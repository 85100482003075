import React, { useEffect, useState } from "react";
import { Button, Modal, Pagination, Table, Tooltip, Spin, Empty } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import Title from "../../component/PageTitle";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FaEdit, } from "react-icons/fa";
import { useGetIncomeQuery } from "../../slices/getSlice";
import { useSelector } from "react-redux";
import { RxCrossCircled } from "react-icons/rx";
import { useDeleteIncomeMutation } from "../../slices/apiSlice";
import { HiOutlineTrash } from "react-icons/hi2";
import { toast } from "react-toastify";

const Income = () => {
    const currencyGlobal = useSelector((state) => state?.persistedReducer?.currency?.currency_symbol)
    const { loading, setLoading } = useOutletContext();
    const currency = currencyGlobal ? currencyGlobal : '';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState("");
    const pageSize = 5
    const { data: getIncome, isLoading, isError, refetch, error } = useGetIncomeQuery();
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
 useEffect(()=>{
refetch()
if(getIncome){
    setLoading(false)
}

 },[])

    const [deleteIncome, { }] = useDeleteIncomeMutation();
    const handleOk = async () => {
        const res = await deleteIncome(selectedRecord);
        if (res?.data?.message) {
            const totalPages = Math.ceil((dataSource.length - 1) / pageSize);
            if (currentPage > totalPages) {
                setCurrentPage(totalPages);
            }
            refetch();
            setIsModalOpen(false);
            toast.success(res?.data?.message)
        }
        else {
            toast.error("Not able to delete income")
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRecord("");
    };
    const dataSource = getIncome?.data ? getIncome.data.map((item) => ({
        property_id: item.property.name_of_property,
        propertyId: item.property.id,
        category_id: item.category.name,
        categoryId: item.category.id,
        amount: item.amount,
        start_date: item.start_date,
        end_date: item.end_date,
        id: item.id
    })) : [];

    const handleChangePage = (page) => {
        setCurrentPage(page);

    };
  
    const Columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, record, index) =>
                ((currentPage - 1) * pageSize + index + 1)

        },
        {
            title: "Property Name",
            dataIndex: "property_id",
        },
        {
            title: "Category",
            dataIndex: "category_id",
        },
        {
            title: "Income",
            dataIndex: "amount",
            render: (amount) => `${currency}${amount}`,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <div className="action-container">
                        <Tooltip title="Edit">
                            <button
                                className="btn btnEdit me-2"
                                onClick={() => { navigate('/add_income', { state: record }) }}
                            >
                                <FaEdit />
                            </button>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <button
                                className="btn btnDelete me-2"
                                onClick={() => {
                                    setSelectedRecord(record.id);
                                    setIsModalOpen(true);
                                }}
                            >
                                <HiOutlineTrash />
                            </button>
                        </Tooltip>
                    </div>

                );
            },
        },
    ];

    return (
        <>
        {loading?
            <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>:
        <div className="mainWrapper">
            {/* Button and Title Section */}
            <div className="card titleCard mb-4">
                <div className="card-body">
                    <Title title="All Income" id="income" />
                    <Button
                        type="primary"
                        className="link"
                        style={{
                            backgroundColor: colors?.primary || "#F47A3A",
                        }}
                        onClick={() => (navigate('/add_income'))}
                    >
                        {"Add Income"}{" "}
                        <IoIosAddCircleOutline style={{ marginLeft: "10px" }} />
                    </Button>
                </div>
            </div>

            <div className="card tableCard">
                <div className="card-body">
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Spin />
                    </div>
                    ) : isError && dataSource.length < 0 ? (
                        <h4>An error occurred</h4>
                    ) : (
                        <div className="table-responsives">
                            {dataSource.length > 0 ? (
                                <>
                                    <Table
                                        rowKey={record => record.id}
                                        rowClassName={() => "editable-row"}
                                        bordered
                                        dataSource={dataSource}
                                        columns={Columns}
                                      
                                        scroll={{ x: '100%' }}
                                        pagination={{
                                            pageSize,
                                            current: currentPage,
                                            onChange: page => setCurrentPage(page),
                                        }}
                                    />
                                 
                                </>
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data found"/>
                            )}

                        </div>
                    )}
                </div>
            </div>
            <Modal
                className="deleteModal"
                title="Delete Income"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={false}
            >
                <div className="iconRow">
                    <RxCrossCircled />
                </div>
                <div className="textRow">
                    <h3>Are you sure?</h3>
                    <p>Do you really want to delete this income?</p>
                </div>
                <div className="buttonRow">
                    <Button className="btnOutlined me-2" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className="btnFilled text-white" onClick={() => handleOk()}>
                        Delete
                    </Button>
                </div>
            </Modal>
        </div>
}
</>
    );
};
export default Income;