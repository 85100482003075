import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { fetchDetail_Url } from '../Helper';

const getToken = () => {
  const persistedRoot = localStorage.getItem('persist:root');
  if (!persistedRoot) return null;
  const persistedRootObj = JSON.parse(persistedRoot);
  const userObj = persistedRootObj?.user ? JSON.parse(persistedRootObj.user) : null;
  return userObj?.user?.token || null;
};
    
export const settingSlice = createApi({
  reducerPath: 'settingSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: fetchDetail_Url,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`); 
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
 
setting: builder.mutation({
  query: ({data,id}) =>({
    url: `setting/${id}`,
    method: 'POST',
    body: data,
}),
invalidatesTags: ['Setting'],  
}),

  }),
 
  tagTypes: ['Setting'], 
});

export const {useSettingMutation} = settingSlice;
