import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Row, Col, Modal } from "antd";
const { Item: FormItem } = Form;

const CommonModal = ({
    title,
    open,
    close,
    form,
    onFinish,
    loading,
    fields,
    FormInitialValues = {},
    formType,
}) => {
    const [formValues, setFormValues] = useState();
    const [disabledBtn, setDisabledButton] = useState(false);
    const [isLabelField, setIsLabelField] = useState(false);

    useEffect(() => {
        form.setFieldsValue({ ...FormInitialValues });
    }, [FormInitialValues]);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleDropdownVisibleChange = (value) => {
        if (value) {
            setDropdownVisible(true);
        } else {
            setDropdownVisible(false);
        }
    };

    const handleValuesChange = (changedValues, allValues) => {
        setDisabledButton(false);
        if (title === "Edit Group" || title === "Add Group") {
            setFormValues({ ...allValues });

        } else {
            setFormValues(allValues);

        }
    };

    const handleOnFinish = () => {
        setDisabledButton(true);
        onFinish(formValues, formType);
    };

    const handleLabelField = () => {
        setIsLabelField(false);
    };

    const manageLabelField = () => {
        setIsLabelField(true);
    };

    return (
        <Modal
            forceRender
            open={open}
            onOk={handleOnFinish}
            title={title}
            onCancel={close}
            footer={null}
            className="assign_model"
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleOnFinish}
                //  onFinish={() => ()}
                onValuesChange={handleValuesChange}
                initialValues={FormInitialValues ? FormInitialValues : formValues}
            >
                <Row>
                    {fields.map((field, index) => (
                        <Col
                            key={field?.name}
                            md={field?.colSpan || 24}
                            offset={field?.offset || 0}
                        >
                            {field.component === "input" && (

                                <FormItem
                                    label={field?.label}
                                    name={field?.name}
                                // rules={field?.rules}
                                rules={[
                                    {
                                    required: true,
                                    },
                                ]}
                                >
                                    {isLabelField && (
                                        <Input
                                            placeholder="Enter label text here..."
                                            type="text"
                                            className="form-control mb-4"
                                        />
                                    )}
                                    {field.type === "password" ? (
                                        <Input.Password
                                            placeholder={field.placeholder}
                                            prefix={field.prefix}
                                            type={field.type}
                                            className="form-control"
                                        />
                                    ) : (
                                        <Input
                                            placeholder={field.placeholder}
                                            prefix={field.prefix}
                                            type={field.type}
                                            className="form-control"
                                        />
                                    )}
                                    {/* <button
                                        type="button"
                                        className="btn linkBtn"
                                        onClick={isLabelField ? handleLabelField : manageLabelField}
                                    >
                                        {isLabelField ? 'Save Label' : 'Edit Label'}
                                    </button> */}
                                </FormItem>
                            )}
                            {field.component === "select" && (
                                <FormItem
                                    label={field.label}
                                    name={field.name}
                                    rules={field.rules}
                                >

                                    <Select
                                        id={index}
                                        showSearch={field.mode === 'multiple'}
                                        onSelect={() => setDropdownVisible(false)}
                                        onSearch={handleDropdownVisibleChange}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        dropdownRender={(originNode) => (
                                            <div
                                                style={{
                                                    display: field.mode === 'multiple' ? dropdownVisible ? 'block' : 'none' : 'block' // Initially hidden
                                                }}
                                            >
                                                {originNode}
                                            </div>)}
                                        className="formControl select-custom"
                                        mode={field.mode || ""}
                                        optionLabelProp="label"
                                        suffixIcon={field.suffixIcon}
                                        placeholder={field.placeholder}
                                        options={field.options}
                                        onChange={(value) => {
                                            setFormValues((prevValues) => ({
                                                ...prevValues,
                                                [field.name]: value,
                                            }));
                                            if (field?.onChange) {
                                                field?.onChange(value);
                                            }
                                        }
                                        }
                                    />
                                </FormItem>
                            )}
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col
                        md={24}
                        className="d-flex justify-content-center align-items-center"
                    >
                        <Button type="primary" htmlType="submit" className="authBtn" disabled={disabledBtn === true ? true : false}>
                        {loading ? "Loading..." : "Save"}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CommonModal;