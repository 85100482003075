import React, { useEffect, useState } from "react";
import { Layout, Spin } from "antd";
import Sidebar from "../Sidebar";
import TopHeader from "../Header";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const { Content } = Layout;

const App = () => {
  
  const [loading, setLoading] = useState(true);




  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <Layout style={{ height: "100vh" }}>   
          <>
            <Sidebar loading={loading}/>
            <Layout className="layout">
              <TopHeader  loading={loading} />
           <Content className="layoutContent">
                <Outlet context={{loading,setLoading}} />
              </Content>
            </Layout>
          </>
      </Layout>
    </div>
  );
};

export default App;
