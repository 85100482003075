import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import { Spin, Alert } from 'antd';
import { useGetRevenueGrowthComparisonMutation } from '../slices/apiSlice';
import { useSelector } from 'react-redux';
import { useGetRevenueGrowthComparisonQuery, useLazyGetRevenueGrowthComparisonQuery } from '../slices/getSlice';

exporting(Highcharts);
exportData(Highcharts);

const ChartRevenue = ({ data, label }) => {
    const [revenueGrowthData, setRevenueGrowthData] = useState([])
    const [fetchRevenueGrowthComparisonData, { data: revenueGrowthComparisonData,isLoading, isError,refetch:refetchRevenueGrowth }] = useLazyGetRevenueGrowthComparisonQuery();

    const colors = useSelector((state) => state?.persistedReducer?.colors);

    let tokenData = localStorage?.getItem('Token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchRevenueGrowthComparisonData({
                    filter_type: data.filter_type,
                    filter_value: data.filter_value,
                    property_ids: data.property_ids
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [data]);

    useEffect(()=>{
        if(revenueGrowthComparisonData)
          {
            setRevenueGrowthData(revenueGrowthComparisonData)
          }
          },[revenueGrowthComparisonData])

    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Spin />
        </div>;
    }

    if (isError) {
        return <Alert message="Error loading data" type="error" />;
    }


    const RevenueChart = (data, title) => {
        // Extract unique property names
        const propertyNames = [...new Set(data?.map(item => item.property_name))];

        // Extract unique months
        const months = [...new Set(data?.map(item => item.month))];

        // Create series data
        const series = months.map(month => ({
            name: month,
            data: propertyNames.map(property => {
                const propertyData = data.find(item => item.property_name === property && item.month === month);
                return propertyData ? propertyData.revenue : 0;
            })
        }));

        const options = {
            chart: {
                type: 'bar'
            },
            title: {
                text: title,
                style: {
                    color: colors !== null && colors !== undefined && colors.secondary !== undefined ? colors.secondary : '#000', // Set your dynamic color here
                    fontSize: '16px',    // Optional: you can also adjust font size or other styles
                }
            },
            xAxis: {
                categories: propertyNames
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Revenue ($)'
                }
            },
            tooltip: {
                shared: true,
                valuePrefix: '$'
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: series
        };
        return options;
    };

    const options = RevenueChart(revenueGrowthData?.data, label);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default ChartRevenue;
