import React, { useState } from "react";
import { Form } from "antd";
import DynamicForm from "../../component/Form";
import { CiLock, CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import images from "../../constant/images";
import { useUserRegisterMutation } from "../../slices/loginRegisterSlice";

const SignUp = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [form] = Form.useForm();
  const [FormInitialValues] = useState({
    email: "",
    password: "",
  });
  const { Logo, SignUpImage } = images;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const fields = [
    {
      component: "input",
      type: "email",
      name: "email",
      placeholder: "Enter Email",
      prefix: <CiMail style={{ color: "#F47939", fontSize: "25px" }} />,
      rules: [
       {
         validator: (_, value) => {
            if (!value) {
              return Promise.reject(new Error("Please input your email"));
            }
            // Trim the value to remove leading and trailing spaces
            const trimmedValue = value.trim();
            // Check if the value after trimming is empty     
            if (trimmedValue === "") {
              return Promise.reject(new Error("Spaces are not allowed"));
            }
            // Check if the value matches the email pattern
            if (!emailRegex.test(trimmedValue)) {
              return Promise.reject(new Error("Please enter a valid email address"));
            }
            return Promise.resolve();
          },
        },
  ],
},
  {
    component: "input",
    type: "password",
    name: "password",
    placeholder: "Enter password",
    prefix: <CiLock style={{ color: "#F47939", fontSize: "25px" }} />,
      rules: [
        {
          required: true,
          message: "Please input your password",
        },
        {
          pattern:
            /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          message:
            "Password must be minimum 8 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
        },
      ],
    },
{
  component: 'input',
    type: 'password',
      dependencies: ['password'],
        name: 'password_confirmation',
          placeholder: 'Enter Confirm password',
            prefix: <CiLock
              style={{ color: '#F47939', fontSize: '25px' }} />,
              rules: [
                {
                  required: true,
                  message: 'Please input your confirm password',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The confirm passwords do not match!'));
                  },
                })
              ],

    },
  ];

const [userRegister, { isLoading}] = useUserRegisterMutation();
const onFinish = async (value) => {
  try {
    const response = await userRegister(value);

    if (response.error) {
      form.resetFields();
      setErrorMessage(response.error.data.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    } else {
      setSuccessMessage(response.data.message);
      form.resetFields();
      setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
    }
  } catch (error) {
    toast.error("Incorrect password and email");
  }
};

return (
  <div className="authWrapper">
    <div className="authInner">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 whiteBg">
            <div className="formWrapper">
              <div className="logoWrap">
                <Logo />
              </div>
              <div className="titleWrap">
                <h2 className="authTitle">Create your Account</h2>
                <p className="authSubTitle">Unlock all features</p>
              </div>
              <div className="formWrap">
                {errorMessage && (
                  <div style={{ color: "red" }}>{errorMessage}</div>
                )}
                {successMessage && (
                  <div style={{ color: "#0FFF50" }}>{successMessage}</div>
                )}
                <DynamicForm
                  form={form}
                  fields={fields}
                  onFinish={onFinish}
                  buttonName={"Sign Up"}
                  loading={isLoading}
                  FormInitialValues={FormInitialValues}
                />
              </div>
              <div className="bottomTextRow">
                <span>You have account?</span>
                <span className="text-wrapper-10">&nbsp;</span>
                <Link to="/login" className="signUpLink">
                  Sign In
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 mobHide">
            <div className="imgWrapper">
              <SignUpImage />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default SignUp;
