
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { fetchDetail_Url } from "../Helper";
import { logout } from "../slices/authSlice";

export const getToken = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const persistedRoot = localStorage?.getItem("persist:root");
      if (!persistedRoot) return null;
      const persistedRootObj = JSON.parse(persistedRoot);
      const userObj = persistedRootObj?.user
        ? JSON.parse(persistedRootObj.user)
        : null;
      resolve(userObj?.user?.token);
      return userObj?.user?.token || null;
    }, 500);
  });
};

const baseQuery = fetchBaseQuery({
  baseUrl: fetchDetail_Url,
  prepareHeaders: async (headers) => {
    const token = await getToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    } else {
      headers.set("Authorization", `Bearer ${localStorage.getItem('Token')}`);
    }
    headers.set("Content-Type", "application/json; charset=UTF-8");
    headers.set("Accept", "application/json");
    return headers;
  },
});

export const baseQueryWithReauth = async (args = {}, api, extraOptions) => {
  const { url } = args || {}; // Ensure args is defined before destructuring
  if (!url) {
    return { error: { status: 400, message: 'Bad request: missing url' } };
  }

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
    return { error: { status: 401, message: 'Unauthorized. You have been logged out.' } };
  }

  return result;
};
