import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchDetail_Url } from '../Helper'
const commonHeaders = {
    'Content-Type': 'application/json; charset=UTF-8',
    'Accept': 'application/json',
};
export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: fetchDetail_Url }),
    endpoints: (builder) => ({
        userRegister: builder.mutation({
            query: (newUser) => ({
                url: 'user/register',
                method: 'POST',
                body: newUser,
                headers: commonHeaders,
            }),

        }),

        userLogin: builder.mutation({
            query: (userDetails) => ({
                url: 'user/login',
                method: 'POST',
                body: userDetails,
                headers: commonHeaders,
            }),


        }),
        forgotPassword: builder.mutation({
            query: (email) => ({
                url: 'forgot-password/email',
                method: 'POST',
                body: email,
                headers: commonHeaders,
            }),


        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: 'password/reset',
                method: 'POST',
                body: data,
                headers: commonHeaders,
            }),

        }),
        resetPasswordSubAccount: builder.mutation({
            query: (data) => ({
                url: 'reset_password',
                method: 'POST',
                body: data,
                headers: commonHeaders,
            }),
        })
        
    })
})

export const { useUserRegisterMutation, useUserLoginMutation, useForgotPasswordMutation, useResetPasswordMutation ,useResetPasswordSubAccountMutation} = authApi

