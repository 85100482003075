import React, { useState } from "react";
import { Form } from "antd";
import DynamicForm from "../../component/Form";
import { CiLock } from "react-icons/ci";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useChangePasswordMutation } from "../../slices/apiSlice";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const ChangePassword = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [form] = Form.useForm();
    const [changePassword,{isLoading}] = useChangePasswordMutation();
    const navigate = useNavigate();
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const fields = [
        {
            component: 'input',
            type: 'password',
            label: 'Current Password',
            name: 'current_password',
            placeholder: 'Enter Current password',
            prefix: <CiLock
                style={{ color: colors?.primary || "#F47A3A", fontSize: '25px' }} />,
            rules: [
                {
                    required: true,
                    message: 'Please input your password',
                },
            ],
            colSpan: 12,
        },

        {
            component: 'input',
            type: 'password',
            label: 'New Password',
            name: 'new_password',
            placeholder: 'Enter New password',
            prefix: <CiLock
                style={{ color: colors?.primary || "#F47A3A", fontSize: '25px' }} />,
            rules: [
                {
                    required: true,
                    message: 'Please input your password',
                },
                {
                    pattern: /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    message: 'Password must be minimum 8 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character.',
                }

            ],
            colSpan: 12,
        },

        {
            component: 'input',
            type: 'password',
            dependencies: ['new_password'],
            label: 'Confirm Password',
            name: 'new_password_confirmation',
            placeholder: 'Enter Confirm password',
            prefix: <CiLock
                style={{ color: colors?.primary || "#F47A3A", fontSize: '25px' }} />,
            rules: [
                {
                    required: true,
                    message: 'Please input your password',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('new_password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The confirm passwords do not match!'));
                    },
                })
            ],
            colSpan: 12,
        },
    ];

    const onFinish = async (value) => {
        const response = await changePassword(value);
        if (response.error) {
            setErrorMessage(response.error.data.message);
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        } else {
            toast.success('Password has been changed successfully');
            navigate('/dashboard')

        }
    };

    return (
        <>
            {errorMessage && (
                <div style={{ color: 'red' }}>
                    {errorMessage}
                </div>
            )}
            <DynamicForm
                form={form}
                fields={fields}
                onFinish={onFinish}
                buttonName={"Save"}
                loading={isLoading}
            />
        </>

    );
}

export default ChangePassword;
