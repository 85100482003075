import React, { useEffect, useRef, useState } from "react";
import Title from "../../component/PageTitle";
import { Button, Tooltip, Space, Input, Table, Modal, Collapse, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
    useGetProviderTypeFromPropertyProviderQuery,
    useGetPropertyProvidersQuery,
    useGetUtilityCategoryQuery,
    useGetPropertyProvidersByStreamIdQuery
} from "../../slices/getSlice";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useDeletePropertyProviderMutation } from "../../slices/apiSlice";
import { FaEdit } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { HiOutlineTrash } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


const ViewProperty = () => {
    const [UtilityCategory, setUtilityCategory] = useState();
    const [UtilityCategoryName, setUtiltityCategoryName] = useState();
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { loading, setLoading } = useOutletContext();
    const propertyId = location?.state?.propertyData?.id;
    const propertyName = location?.state?.propertyData?.name_of_property;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState("");
    const [deletepropertyprovider, { }] = useDeletePropertyProviderMutation();
    const {
        data: getUtilityCategory = [],
    } = useGetUtilityCategoryQuery();
    const [isRefetching, setIsRefetching] = useState(false);

    const currencyGlobal = useSelector((state) => state?.persistedReducer?.currency?.currency_symbol)
    const currency = currencyGlobal ? currencyGlobal : '';
    const [isClicked, setIsClicked] = useState(false);
    let propertyDataState = location?.state?.propertyData;

    const {
        data: getProviderType = [],
        isLoading: isProviderTypeLoading,
        refetch: refetchProvider,
    } = useGetProviderTypeFromPropertyProviderQuery(
        propertyId ? propertyId : location?.state?.property_id
    );


    const [propertyData, setPropertyData] = useState(location?.state?.propertyData);

    const data = React.useMemo(() => ({
        skip: isClicked,
        propertyId: propertyId || location?.state?.property_id,
        providerTypesId: UtilityCategory || location?.state?.provider_type_id,
        propertyName: propertyName,
    }), [isClicked, propertyId, UtilityCategory, location?.state?.provider_type_id, propertyName]);


    const {
        data: getPropertyProviders,
        isLoading: isPropertyProvidersLoading,
        refetch: refetchPropertyProvider,
        isError: isPropertyProviderError,
    } = useGetPropertyProvidersQuery(data);

    useEffect(() => {
        refetchProvider();
        if (getProviderType.length > 0) {
            setIsRefetching(true);
            const firstProviderTypeId = getProviderType[0]?.provider_type_id || null;
            setUtilityCategory(firstProviderTypeId);
            
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getProviderType]);

    useEffect(() => {
        if (UtilityCategory) {
            setIsRefetching(true);
            refetchPropertyProvider().finally(() => {
                setIsRefetching(false);
            });
        }
    }, [UtilityCategory, refetchPropertyProvider]);


    const handleCollapseChange = (key) => {
        setUtilityCategory(key);
        setIsClicked(true);
    };


    const handleOk = async (id) => {
        const res = await deletepropertyprovider(selectedRecord);
        if (res?.data?.message) {
            refetchPropertyProvider();
            refetchProvider()
            setIsModalOpen(false);
            toast.success(res?.data?.message)
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRecord("");
    };
    const [pagesize, setPageSize] = useState(5);
    const [current, setCurrent] = useState(1);
    const [showGroup, setShowGroup] = useState(false);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {

        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    useEffect(() => {
        refetchPropertyProvider();
    }, [isClicked])

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        className=""
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Clear Search
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const showGroupSection = () => {
        setShowGroup(!showGroup);
    };

    const handleClick = (detail) => {

        navigate("/property_details", {
            state: {
                data: detail,
                propertyData: propertyDataState,
                propertyid: propertyId,
                propertyName: propertyName,
                isEdit: false
            },
        });
    };

    const utilityData = getPropertyProviders?.data?.length ? getPropertyProviders?.data?.flatMap((item, index) => {
        return {
            key: index * 100 + index,
            provider_id: item?.provider?.provider_name,
            company_id: item?.company?.company_name,
            companyId: item?.company_id,
            amount: item?.amount,
            due_date: item?.due_date,
            id: item?.id,
            provider_id: item?.provider?.provider_name,
            frequency: item?.frequency,
            property_id: item?.property_id,
            provider_type_id: item?.provider_type_id,
            providerId: item?.provider_id,
            form_fields: item?.form_fields,
            start_date: item?.start_date,
            category_id: item?.category?.name,
            stream_id: item?.stream_id,
            payment_date: item.payment_date,
            next_payment_date: item.next_payment_date,
            reminder: item.reminder,
            reminder_duration: item.reminder_duration
        }

    }) : '';

    // const utilityData = transformedData;

    const utilityColumns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (text, record, index) => {
                return index + 1;
            },
        },

        {
            title: "Utility company",
            dataIndex: "company_id",
            key: "company",
            ...getColumnSearchProps("company_id"),
            sorter: (a, b) => (a.company_id || '').localeCompare(b.company_id || ''),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (record) => {

                if (record === null || record === undefined) {
                    return <p>{`${currency}0`}</p>;
                } else {
                    return <p>{`${currency}${record}`}</p>;
                }
            },
            key: "amount",
            sorter: {
                compare: (a, b) => a.record - b.record,
            },
        },

        {
            title: "Payment Date",
                dataIndex: "start_date",
                render: (_, record) => {
                    if (record.category_id === "One time charges" || record.category_id === "Old data" || record.category_id === "Recurring") {
                        return record.start_date ? record.start_date : "-";
                    }
                    return "-";
                },
                sorter: (a, b) => {
                    const dateA = new Date(a.payment_date);
                    const dateB = new Date(b.payment_date);
                    return dateA - dateB; // Compare dates
                },
        },

        {
            title: "Next Payment Date",
            dataIndex: "next_payment_date",
            key: "next_payment_date",
            sorter: {
                compare: (a, b) => a.record - b.record,
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <div className="action-container">
                        <Tooltip title="Edit">
                            <button
                                className="btn btnEdit me-2"
                                onClick={() => {

                                    navigate("/property_details", {

                                        state: {
                                            data: record,
                                            providerType: UtilityCategory,
                                            providerTypeName: UtilityCategoryName,
                                            propertyData: propertyDataState,
                                            isEdit: true
                                        },
                                    });
                                }}
                            >
                                <FaEdit />
                            </button>
                        </Tooltip>

                        <Tooltip title="Delete">
                            <button
                                className="btn btnDelete me-2"
                                onClick={() => {
                                    setSelectedRecord(record.id);
                                    setIsModalOpen(true);
                                }}
                            >
                                <HiOutlineTrash />
                            </button>
                        </Tooltip>
                    </div>

                );
            },
        },
    ];

    const ExpandedRow = ({ record, mainIndex }) => {
        const {
            data: PropertyProviders,
            isLoading: isPropertyProvidersLoading,

        } = useGetPropertyProvidersByStreamIdQuery(record.stream_id);

        const columns = [
            {
                title: "S.No.",
                dataIndex: "serial",
                key: "serial",
                render: (text, record, index) => {
                    return mainIndex + "." + (index + 1);
                },
            },

            {
                title: "Utility company",
                dataIndex: "company_id",
                key: "company",
                ...getColumnSearchProps("company_id"),
                sorter: (a, b) => (a.company_id || '').localeCompare(b.company_id || ''),
            },
            {
                title: "Amount",
                dataIndex: "amount",
                render: (record) => {

                    if (record === null || record === undefined) {
                        return <p>{`${currency}0`}</p>;
                    } else {
                        return <p>{`${currency}${record}`}</p>;
                    }
                },
                key: "amount",
                sorter: {
                    compare: (a, b) => a.record - b.record,
                },
            },

            {
                title: "Payment Date",
                dataIndex: "start_date",
                render: (_, record) => {
                    if (record.category_id === "One time charges" || record.category_id === "Old data" || record.category_id === "Recurring") {
                        return record.start_date ? record.start_date : "-";
                    }
                    return "-";
                },
                sorter: (a, b) => {
                    const dateA = new Date(a.payment_date);
                    const dateB = new Date(b.payment_date);
                    return dateA - dateB; // Compare dates
                },
            },

            {
                title: "Next Payment Date",
                dataIndex: "next_payment_date",
                key: "next_payment_date",
                sorter: {
                    compare: (a, b) => a.record - b.record,
                },
            },
            {
                title: "Action",
                dataIndex: "action",
                render: (_, record) => {
                    return (
                        <div className="action-container">
                            <Tooltip title="Edit">
                                <button
                                    className="btn btnEdit me-2"
                                    onClick={() => {

                                        navigate("/property_details", {

                                            state: {
                                                data: record,
                                                providerType: UtilityCategory,
                                                providerTypeName: UtilityCategoryName,
                                                propertyData: propertyDataState,
                                                isEdit: true
                                            },
                                        });
                                    }}
                                >
                                    <FaEdit />
                                </button>
                            </Tooltip>

                            <Tooltip title="Delete">
                                <button
                                    className="btn btnDelete me-2"
                                    onClick={() => {
                                        setSelectedRecord(record.id);
                                        setIsModalOpen(true);
                                    }}
                                >
                                    <HiOutlineTrash />
                                </button>
                            </Tooltip>
                        </div>

                    );
                },
            },
        ];
        const transformedData = PropertyProviders?.data?.flatMap((item, index) => {
            return {
                key: index * 100 + index,
                provider_id: item?.provider?.provider_name,
                company_id: item?.company?.company_name,
                companyId: item?.company_id,
                amount: item?.amount,
                due_date: item?.due_date,
                id: item?.id,
                provider_id: item?.provider?.provider_name,
                frequency: item?.frequency,
                property_id: item?.property_id,
                provider_type_id: item?.provider_type_id,
                providerId: item?.provider_id,
                form_fields: item?.form_fields,
                start_date: item?.start_date,
                category_id: item?.category?.name,
                stream_id: item?.stream_id,
                payment_date: item.payment_date,
                next_payment_date: item.next_payment_date
            }

        });

        return isPropertyProvidersLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Spin />
            </div>
        ) : (
            <Table tableLayout="fixed" columns={columns} dataSource={transformedData} pagination={false} showHeader={false} />
        );
    };

    const expandedRowRender = (record, index) => <ExpandedRow record={record} mainIndex={index + 1} />;
    const expandIcon = ({ expanded, onExpand, record }) => {
        if (record.stream_id) {
            return (
                <span
                    onClick={(e) => onExpand(record, e)}
                    style={{ cursor: 'pointer' }}
                >
                    {expanded ? '-' : '+'}
                </span>
            );
        }
        return null; // No expand icon for records without stream_id
    };
    const items = getProviderType ? (
        getProviderType.flatMap((item) => {
            const providerTypes = Array.isArray(item.provider_type) ? item.provider_type : [item.provider_type];

            return providerTypes.map((property) => {
                const totalAmount = getPropertyProviders?.data
                ?.filter((data) => data.provider_type_id === property.id && parseFloat(data.amount) > 0)
                .reduce((acc, curr) => acc + parseFloat(curr.amount), 0);

                return {
                    key: property.id,
                    label: property.name,
                    extra: (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Button
                                className="btnFilled text-white"
                                onClick={() => {
                                    setUtilityCategory(property.id);
                                    setUtiltityCategoryName(property.name);
                                }}
                            >
                                {"Manage Expense"}
                            </Button>
                            {totalAmount && !isPropertyProvidersLoading && !isPropertyProviderError ? (
                                <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                                    Total: ${totalAmount?.toFixed(2)}
                                </span>
                            ) : ''}
                        </div>
                    ),
                    children: isPropertyProvidersLoading || isRefetching ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin />
                        </div>
                    ) : (
                        <Table
                            tableLayout="fixed"
                            className="tableFixed"
                            rowKey={(record) => record?.id}
                            bordered
                            dataSource={utilityData}
                            expandable={{
                                expandedRowRender,
                                defaultExpandedRowKeys: ["0"],
                                expandIcon,
                            }}
                            loading={isRefetching}
                            scroll={{ x: '100%' }}
                            columns={utilityColumns}
                            showSorterTooltip={false}
                            pagination={
                                utilityData?.length >= 5 ? {
                                    total: utilityData?.length,
                                    pageSize: pagesize,
                                    onChange: (page, pageSize) => {
                                        setPageSize(pageSize);
                                        setCurrent(page);
                                    },
                                    showSizeChanger: false,
                                    pageSizeOptions: ["5", "10", "20", "30", "50"],
                                } : ""
                            }
                        />
                    ),
                };
            });
        })
    ) : (
        <p>No data found</p>
    );

    return (
        <>
        {loading?
            <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>:
        <div className="mainWrapper">
            <div className="card viewCard mb-4">
                <div className="card-body d-block">
                    <Title
                        title="Manage Utility Categories"
                        subtitle={
                            `${propertyData?.name_of_property} - ` +
                            `${propertyData?.address}, ` +
                            `${propertyData?.city_id ? propertyData?.city_id : propertyData?.city}, ` +
                            `${propertyData?.state_id ? propertyData?.state_id : propertyData?.state}`
                        }
                    />
                    <div className="propertyDetail mt-2">
                        <span className="colLabel">Property Type: {propertyData?.property_type_name?.provider_name ?? propertyData?.property_type_name}</span>

                    </div>
                </div>
            </div>

            <div className="card tableCard">
                <div className="card-header d-flex align-items-center justify-content-between border-0">
                    <h5 className="card-title">Add Property Expense</h5>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="btnOutlined me-3"
                        onClick={showGroupSection}
                    >
                        Add Expense
                    </Button>
                </div>
                {showGroup && (
                    <div className="card-body p-3">
                        {getUtilityCategory?.data?.map((utility) => (
                            <div className="provider-content" key={utility.id}>
                                <div>{utility.name}</div>
                                <Button
                                    className="add-new"
                                    onClick={() => handleClick(utility)}
                                >
                                    {" "}
                                    {"Add New"}
                                </Button>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="card collapseCard">
                <div className="card-header">
                    <h5>Manage Property Expense</h5>
                </div>
                <div className="card-body">
                    {isProviderTypeLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin />
                        </div>
                    ) : getProviderType.length ? (
                        <Collapse
                            accordion
                            items={items}
                            onChange={handleCollapseChange}
                            showArrow={false}
                            expandIcon={false}
                        />
                    ) : (
                        <p>No data Found</p>
                    )}
                </div>
            </div>
            <Modal
                className="deleteModal"
                title="Delete Expense"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={false}
            >
                <div className="iconRow">
                    <RxCrossCircled />
                </div>
                <div className="textRow">
                    <h3>Are you sure?</h3>
                    <p>Do you really want to delete this expense?</p>
                </div>
                <div className="buttonRow">
                    <Button className="btnOutlined me-2" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className="btnFilled text-white" onClick={() => handleOk()}>
                        Delete
                    </Button>
                </div>
            </Modal>
        </div>
}</>
    );
};

export default ViewProperty;