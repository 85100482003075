
import axios from "axios";
axios.interceptors.request.use(function (config) {
    let tokenData = localStorage.getItem("Token");
    const token = `Bearer ${tokenData}`;
    config.headers.Authorization = token;
    config.headers["Content-Type"] = "application/json";
    return config;
});

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axios;