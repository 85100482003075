import {fetchDetail_Url} from "../Helper";
import Http from "../Http";

export function transactions() {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(fetchDetail_Url + "transactions")
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function categorizePlaidData(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(fetchDetail_Url + "categorize_plaid_data")
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function marketAnalysis(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(fetchDetail_Url + "analyzeMarket",value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function reportAnalysis() {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(fetchDetail_Url + "insight_report")
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }