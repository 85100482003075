import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import { Button,Spin } from "antd";
import { TbRefresh } from "react-icons/tb";
import * as expenseService from "../../services/expensesServices";
import { useDispatch } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { LoadingOutlined } from '@ant-design/icons';
import { useOutletContext } from "react-router-dom";

export default function ReportAnalysis() {
  const dispatch = useDispatch();
  const [reportAnalysisData, setReportAnalysisData] = useState({ monthly_report: {}, financial_habits_analysis: {} });
  const [loader, setLoader] = useState(false);
  const { loading, setLoading } = useOutletContext();
 const[reportText,setReportText] = useState()
  const handleTransactionData = () => {
    setLoader(true);
    dispatch(expenseService.reportAnalysis())
      .then((response) => {
        setReportAnalysisData(response);
        setLoading(false)
        setReportText(response)
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error({ error });
      });
  };

  const { monthly_report = {}, financial_habits_analysis = {} } = reportAnalysisData;

  const getTopSpendingCategoriesData = (report) => {
    const categories = [];
    Object.values(report).forEach(month => {
      Object.entries(month.top_spending_categories || {}).forEach(([category, subcategories]) => {
        Object.entries(subcategories).forEach(([subcategory, amount]) => {
          categories.push({
            name: `${category} - ${subcategory}`,
            y: amount,
          });
        });
      });
    });
    return categories;
  };

  const topSpendingCategoriesData = getTopSpendingCategoriesData(monthly_report);

  const optionsTopSpendingCategories = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Top Spending Categories',
    },
    series: [{
      name: 'Amount',
      data: topSpendingCategoriesData,
    }],
  };

  const getFinancialHabitsData = (analysis) => {
    return Object.entries(analysis.top_spending_categories || {}).map(([category, amount]) => ({
      name: category,
      y: amount,
    }));
  };

  const financialHabitsData = getFinancialHabitsData(financial_habits_analysis);

  const optionsFinancialHabits = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Financial Habits Analysis - Top Spending Categories',
    },
    series: [{
      name: 'Amount',
      data: financialHabitsData,
    }],
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const shouldRenderDiv = !isEmptyObject(monthly_report) && !isEmptyObject(financial_habits_analysis);

useEffect(()=>{
  handleTransactionData()
},[])
  return (
    <>
    {loading?
        <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(255, 255, 255, 1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>:
    <>
      <div className="mainWrapper">
        <div className="card titleCard mb-4">
          <div className="card-body d-flex justify-content-between align-items-center">
            <Title title="Report Analysis" />
            {/* <Button onClick={handleTransactionData} className="generate-report-button btnFilled"> */}
            {/* {loader ? (
              <>
              <Spin indicator={<LoadingOutlined spin />} size="large" />&nbsp;...Processing Data

              </>
            ):(
              <> */}
              Fetch Plaid Data{" "}
              <span className="refresh-span">
                <TbRefresh className="refresh-icon" />
              </span>
              {/* </>
            )}
              */}
            {/* </Button> */}
          </div>
        </div>
      </div>
       {loader ? (
              <>
              <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />

              </>
            ):(
              <>
      {shouldRenderDiv ? (
  <div>
    <HighchartsReact highcharts={Highcharts} options={optionsTopSpendingCategories} />
    <HighchartsReact highcharts={Highcharts} options={optionsFinancialHabits} />
  </div>
) : (
  <p>{reportText}</p>  // Displays the data as a JSON string
)}
 </>
            )}
             
    </>
}</>
  );
}
