import { BrowserRouter } from "react-router-dom";
import AppRoute from "../src/routes/routes";
import "./styles/style.scss";


const App = () => {
   
    return (
        <>
            <BrowserRouter>
                <AppRoute />
            </BrowserRouter>
        </>

    );
}

export default App; 