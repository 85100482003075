import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Spin, message } from "antd";
import DynamicForm from "../../component/Form";
import { CiMail } from "react-icons/ci";
import Title from "../../component/PageTitle";
import { RiAdminLine } from "react-icons/ri";
import { CiUser } from "react-icons/ci";
import {
    useGetPropertiesQuery,
    useOwnerTypeQuery,
    useGetLabelsQuery
} from "../../slices/getSlice";
import {
    useAddOwnerTypeMutation,
    useEditSubAccountMutation,
    useSubAccountMutation,
} from "../../slices/apiSlice";
import { toast } from "react-toastify";
import { IoHomeOutline } from "react-icons/io5";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import CommonModal from "../../component/CommonModal";
import { LiaUserEditSolid } from "react-icons/lia";
import { useUpdateLabelsMutation } from "../../slices/apiSlice";

const CreateSubAccount = () => {
  const { data: getProperties } = useGetPropertiesQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [labelForm] = Form.useForm();
  const [modal] = Form.useForm();
  const { loading, setLoading } = useOutletContext();
  const [ownerType, setOwnerType] = useState();
  const [properties, setProperties] = useState([]);
  const [labelsModal, setLabelsModal] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [FormInitialValues, setFormInitialValues] = useState(location?.state);
  const [FormInitialValuesDiff, setFormInitialValuesDiff] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [labelUpdate,{isLoading:isLoadingUpdate}] = useUpdateLabelsMutation();
  const {
    data: ownertype,
    isLoading,
    isError,
    refetch: refetchOwnerType,
    error
  } = useOwnerTypeQuery();

  const [addOwnerType,{isLoading:isLoadingAddOwnerType}] = useAddOwnerTypeMutation();
  const [editSubAccount,{isLoading:isLoadingEdit}] = useEditSubAccountMutation();
  const [subAccount,{isLoading:isLoadingAdd}] = useSubAccountMutation();

    useEffect(() => {
        if (ownertype && ownertype.data) {
            const transformedOptions = ownertype.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setOwnerType(transformedOptions);
            setLoading(false)
        }

        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setProperties(transformedOptions);
        }

    }, [ownertype, getProperties]);
  

    const {
        data: labelData,
        error: labelsError,
        refetch: refetchLabels
    } = useGetLabelsQuery('sub_account_form');

      
    useEffect(()=>{
        labelForm.setFieldsValue(labelData)
            },[labelData])

    useEffect(() => {
        setFormInitialValues(location?.state);
    }, [location?.state?.id]);

    if (isLoading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    <Spin />
</div>;
    if (isError) return <p>Error fetching product.</p>;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    const fields = [
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter(labelData?.first_name) || "First Name",
            name: "first_name",
            placeholder: "Enter First Name",
            prefix: <CiUser style={{ color: "#F47939", fontSize: "25px" }} />,
            rules: [
                {
                    required: true,
                    message: "Please input your First Name!",
                },
                {
                    pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                    message: 'First name  must only contain letters',
                },
            ],
            colSpan: 12,
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter(labelData?.last_name) || "Last Name",
            name: "last_name",
            placeholder: "Enter Last Name",
            prefix: <CiUser style={{ color: "#F47939", fontSize: "25px" }} />,
            rules: [
                {
                    required: true,
                    message: "Please input your LastName!",
                },
                {
                    pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                    message: '  Last name  must only contain letters',
                },
            ],
            colSpan: 12,
        },
        {
            component: "input",
            type: "email",
            label: capitalizeFirstLetter(labelData?.email) || "Email",
            name: "email",
            disabled: FormInitialValues?.id ? true : false,
            placeholder: "Enter Email",
            prefix: <CiMail style={{ color: "#F47939", fontSize: "25px" }} />,
            rules: [
                {
                    validator: (_, value) => {
                        if (!value) {
                            return Promise.reject(new Error("Please input your email"));
                        }
                        // Trim the value to remove leading and trailing spaces
                        const trimmedValue = value.trim();
                        // Check if the value after trimming is empty     
                        if (trimmedValue === "") {
                            return Promise.reject(new Error("Spaces are not allowed"));
                        }
                        // Check if the value matches the email pattern
                        if (!emailRegex.test(trimmedValue)) {
                            return Promise.reject(new Error("Please enter a valid email address"));
                        }
                        return Promise.resolve();
                    },
                },
            ],
            colSpan: 12,
        },
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.owner_type) || "Owner Type",
            name: "ownership_type_id",
            placeholder: "Please select",
            suffixIcon: (
                <RiAdminLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select Owner Type",
                },
            ],
            options: ownerType,
            colSpan: 12,
        },
        {
            component: "select",
            type: "text",
            mode: "multiple",
            label: capitalizeFirstLetter(labelData?.select_property) || "Select Property",
            name: "property_id",
            placeholder: "Please select property",
            suffixIcon: (
                <IoHomeOutline
                    style={{
                        color: "#F47939",
                        fontSize: "25px",
                    }}
                />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select property",
                },
            ],
            options: properties,
        },
    ];

    const modalField = [
        {
            component: "input",
            type: "text",
            label: `Owner Type`,
            name: "name",
            placeholder: `Enter Owner Type`,
            prefix: (
                <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: `Please input your Owner Type!`,
                },
                {
                    pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                    message: 'Owner type name  must only contain letters',
                },
            ],
        },
    ];

    const onFinish = async (values) => {
        setIsSaving(true)
        try {
            let id = location?.state?.id;
            let formValue = {
                first_name: values?.first_name ? values?.first_name : location?.state?.first_name,
                last_name: values?.last_name ? values?.last_name : location?.state?.last_name,
                ownership_type_id: values?.ownership_type_id ? values?.ownership_type_id : location?.state?.ownership_type_id,
                property_id: values?.property_id ? values?.property_id : location?.state?.property_id,
            }
            if (location?.state?.id) {
                if (typeof formValue?.ownership_type_id == "string") {
                    let ownerTypeData = ownerType.find(
                        (owner) => owner.label === formValue.ownership_type_id
                    );
                    formValue.ownership_type_id = ownerTypeData?.value;
                }
                let filterdProperties = [];
                if (formValue?.property_id?.length) {
                    formValue?.property_id?.map((item) => {
                        if (typeof item === "number") {
                            filterdProperties.push(item);
                        } else {
                            const findProperty = properties?.find((record) => {
                                return item === record.label;
                            });

                            if (findProperty !== undefined) {
                                filterdProperties.push(findProperty.value);
                            }
                        }
                    });
                    formValue.property_id = filterdProperties;
                }
                const response = await editSubAccount({ data: formValue, id });
                if (response.data) {
                    toast.success("SubAccount has been updated successfully");
                    navigate("/sub_account");
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            } else {
                const response = await subAccount(values);
                if (response?.data) {
                    toast.success(`${response?.data?.message}`);
                    navigate("/sub_account");
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            }
        } catch { } finally {
            setIsSaving(false)
        }

    };

    const AddOwnerType = async (value) => {
        setIsSaving(true);
        try {
            const res = await addOwnerType(value);
            if (res?.data) {
                toast.success("Owner Type has been created successfully");
                refetchOwnerType();
                setModalOpen(false);
            } else {
                toast.error(`${res?.error?.data?.message}`);
            }
        } catch (error) {
            toast.error("An error occurred while adding owner type.");
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        setModalOpen(false)
        setIsSaving(false)
    }
    const openLabelModal = () => {
        setLabelsModal(true);
    };

    const handleLabelModal = () => {
        setLabelsModal(false);
    };

    const updateLabels = async (value) => {
        const convertedData = {
            layout_data: {
                first_name: value.first_name !== undefined ? value.first_name : labelData.first_name,
                last_name: value.last_name !== undefined ? value.last_name : labelData.last_name,
                email: value.email !== undefined ? value.email : labelData.email,
                owner_type: value.owner_type !== undefined ? value.email : labelData.email,
                select_property: value.select_property !== undefined ? value.select_property : labelData.select_property,
            }
        };
        setIsSaving(true);
        try {
            // settingUpdate({ data, id: getUserDetail?.data?.setting?.id });
            await labelUpdate({ label: 'sub_account_form', value: convertedData }).unwrap();
            message.success('Labels updated successfully!'); // Add this line
            refetchLabels();
        }
        catch {
        } finally {
            setIsSaving(false);
            setLabelsModal(false);
            labelForm.resetFields();
        }
    }

    return (
        <>
        {loading?
            <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>:
        <div className="mainWrapper">
            <div className="card titleCard mb-4">
                <div className="card-body d-flex justify-content-between align-items-center">
                    <Title title={FormInitialValues?.id ? 'Edit Sub Account' : "Create Sub Account"} />
                    <div className="btnWrapper">
                        {FormInitialValues?.id ? '' : <Button
                            type="primary"
                            className="btnOutlined "
                            onClick={() => {
                                openLabelModal()
                            }}
                        >
                            Change Labels
                        </Button>}
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btnFilled"
                            onClick={() => {
                                setModalOpen(true);
                                setFormInitialValuesDiff({ name: "" });
                            }}
                        >
                            Add Owner Type
                        </Button>
                    </div>
                </div>
            </div>
            <div className="card tableCard">
        <div className="card-body">
          <DynamicForm
            form={form}
            fields={fields}
            onFinish={onFinish}
            buttonName={FormInitialValues?.id ? 'Save' : "Create Account"}
            FormInitialValues={FormInitialValues}
            ButtonDisable={isSaving}
            loading={FormInitialValues?.id ? isLoadingEdit:isLoadingAdd}
          />
        </div>
      </div>
      <CommonModal
        open={ModalOpen}
        close={handleCancel}
        form={modal}
        FormInitialValues={FormInitialValuesDiff}
        onFinish={AddOwnerType}
        fields={modalField}
        title={"Add Owner Type"}
        ButtonDisable={isSaving}
        loading={isLoadingAddOwnerType}

      />
            <Modal
                title="Change Form Labels"
                open={labelsModal}
                // onOk={handleLabelModal}
                onCancel={handleLabelModal}
                className="assign_model"
                width={720}
                footer={false}
            >
                <div className="modalBody">
                    <Form
                        name="labelForm"
                        className="modalForm"
                        layout="vertical"
                        onFinish={updateLabels}
                        form={labelForm}
                    >
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item
                                    name="first_name"
                                    label={capitalizeFirstLetter(labelData?.first_name) || "First Name"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="last_name"
                                    label={capitalizeFirstLetter(labelData?.last_name) || "Last Name"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="email"
                                    label={capitalizeFirstLetter(labelData?.email) || "Email"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="owner_type"
                                    label={capitalizeFirstLetter(labelData?.owner_type) || "Owner Type"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="select_property"
                                    label={capitalizeFirstLetter(labelData?.select_property) || "Select Properties"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-12 text-center mt-4">
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="authBtn" >
                                        Save
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
        }
</>
    );

};

export default CreateSubAccount;
