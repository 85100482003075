import React, { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useGenrateLinkTokenQuery } from '../slices/getSlice';
import { useAddAccessTokenMutation } from '../slices/apiSlice';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router";
import { Button, Spin } from 'antd';

const PlaidLink = () => {
    const { data: generateToken, isLoading, error } = useGenrateLinkTokenQuery();

    if (isLoading) {
        return <Spin size='14'/>;
    }

    if (error) {
        return <div>Error generating link token</div>;
    }

    return generateToken?.token?.link_token != null ? (
        <LinkAccount linkToken={generateToken?.token?.link_token} />
    ) : (
        <div>No link token available</div>
    );
};

const LinkAccount = ({ linkToken }) => {
    const navigate = useNavigate();
    const [setAccessToken] = useAddAccessTokenMutation();

    const onSuccess = useCallback(
        async (public_token) => {
            try {
                const response = await setAccessToken({ public_token });

                if (response.error) {
                    toast.error(response.error.data.message);
                } else {
                    toast.success('Account Connected');
                    navigate('/dashboard');
                }
            } catch (error) {
                toast.error('Error connecting account');
            }
        },
        [setAccessToken, navigate]
    );

    const config = {
        token: linkToken,
        onSuccess,
    };

    const { open, ready } = usePlaidLink(config);

    return (
        <Button
            type="primary"
            className="btnOutlined"
            onClick={() => open()} 
            disabled={!ready}
        >
            Link Account
        </Button>
    );
};

export default PlaidLink;
