import { useEffect, useState } from 'react';
import { theme, Avatar, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Header } from "antd/es/layout/layout";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    useGetUserDetailQuery,
} from "../../slices/getSlice";
import { colorsData } from "../../slices/authSlice";
import { RxHamburgerMenu } from "react-icons/rx";
import CustomDrawer from '../Drawer/drawer';
import images from '../../constant/images';

const TopHeader = ({loading}) => {
    const userData = useSelector((state) => state?.persistedReducer?.user?.user?.user);
    const user = useSelector((state) => state?.persistedReducer?.detail?.user?.data?.first_name);
    const data = useSelector((state) => state.persistedReducer);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const parent_id = data?.user?.user?.parent_id;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { Logo } = images;

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const {
        data: getUserDetail,refetch
    } = useGetUserDetailQuery();

    const menuItems = [
        {
            key: '0',
            label: <Link to="/profile" className="link">My Profile</Link>,
        },
    ];

    const isValidHexColor = (color) => {
        return /^#([0-9A-F]{3}){1,2}$/i.test(color);
    };

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    useEffect(() => {
        if (getUserDetail !== undefined) {
            let apiData = getUserDetail?.data?.setting?.custom_data?.colors !== undefined
                ? getUserDetail?.data?.setting.custom_data.colors
                : '';

            // Fallback default colors
            let primary = apiData !== undefined && apiData.primary ? apiData.primary : '#f47939';
            let secondary = apiData !== undefined && apiData.secondary ? apiData.secondary : '#3a54a5';

            // Validate hex colors before applying them
            if (!isValidHexColor(primary)) {
                primary = '#f47939'; // Fallback to default if invalid
            }

            if (!isValidHexColor(secondary)) {
                secondary = '#3a54a5'; // Fallback to default if invalid
            }

            // Dynamically update the CSS variables
            document.documentElement.style.setProperty('--primary-color', primary);
            document.documentElement.style.setProperty('--secondary-color', secondary);

            // Dispatch the valid colors
            dispatch(
                colorsData({
                    primary: primary,
                    secondary: secondary
                })
            );
        }
        refetch()
        // eslint-disable-next-line
    }, [getUserDetail,user]);

    return (
        loading ? (
            <>
            </>
         ) : (
      <>
            <Header
                style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    width: "100%",
                    background: colorBgContainer,
                }}
            >
                <div className="row h-100">
                    <div className="col-md-8">
                        <div className="logoContainer tabLogo">
                            <Logo onClick={() => {
                                navigate('/dashboard');
                            }} />
                        </div>
                    </div>
                    <div className="col-md-4 text-end h-100">
                        <div className='dFlex'>
                            <div className="logoContainer mobileLogo">
                                <Logo onClick={() => {
                                    navigate('/dashboard');
                                }} />
                            </div>
                            <div className="rightButtonSection h-100">
                                    <div className="profiles">
                                        <NavLink to={'/profile'} className="link" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                            <Avatar
                                                style={{
                                                    backgroundColor: colors?.primary || '#F47939',
                                                    cursor: 'pointer',
                                                }}
                                                icon={<UserOutlined />}
                                            />
                                            <span className="username link" style={{ marginLeft: '10px' }}>
                                                {user ? user : userData}
                                            </span>
                                        </NavLink>
                                    </div>
                                <div className="drawerIcon">
                                    <button type='button' className='btn menuBtn' onClick={openDrawer}>
                                        <RxHamburgerMenu />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Header>
            <CustomDrawer isOpen={isDrawerOpen} onClose={closeDrawer} />
        </>)
    )
}
export default TopHeader;