import React from 'react';
import { useGetLabelsQuery } from "../../slices/getSlice";

const Title = ({ title, subtitle, id }) => {
    const {
        data: labelData = {}, // Default to an empty object to avoid errors
        error: labelsError,
        isLoading,
    } = useGetLabelsQuery('menu');

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };


    // Handle the case when the API is loading or there is an error
    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (labelsError) {
        return <p>Error loading labels</p>;
    }

    // Safely check for label data and find the title if labelData exists
    const findTitle = labelData[id] || title;

    return (
        <div className='titleWrapper'>
            <h2 className='cardTitle'>{capitalizeFirstLetter(findTitle)}</h2>
            <p className={subtitle ? 'subtitle' : 'hide'}>{subtitle || ''}</p>
        </div>
    );
};

export default Title;
