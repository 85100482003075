import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchDetail_Url } from "../Helper";
import { baseQueryWithReauth } from "../AuthHelper";

const getToken = async () => {
  // Simulate an asynchronous token fetch
  return new Promise((resolve) => {
    setTimeout(() => {
      const persistedRoot = localStorage.getItem("persist:root");
      if (!persistedRoot) return null;
      const persistedRootObj = JSON.parse(persistedRoot);
      const userObj = persistedRootObj?.user
        ? JSON.parse(persistedRootObj.user)
        : null;
      resolve(userObj?.user?.token);
      return userObj?.user?.token || null;
    }, 500);
  });
};

export const getApi = createApi({
  reducerPath: "getApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    ownerType: builder.query({
      query: (token) => ({
        url: "owner/type",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      }),
      providesTags: ["Owner"],
    }),
    getProperties: builder.query({
      query: () => ({
        url: "properties",
        method: "GET",
      }),
      providesTags: ["Property"],
    }),
    getGroup: builder.query({
      query: () => ({
        url: "group/properties",
        method: "GET",
      }),
      providesTags: ["Group"],
    }),
    getUtilityCategory: builder.query({
      query: () => ({
        url: "provider_types",
        method: "GET",
      }),
      providesTags: ["Providertype"],
    }),
    getUtility: builder.query({
      query: (provider_type_id) => {
        if (provider_type_id) {
          return {
            url: `providers/${provider_type_id}`,
            method: "GET",
          }
        }

      },
      providesTags: ["Provider"],
      

    }),

    getCompany: builder.query({
      query: (provider_id) => {
        if (provider_id) {
          return {
            url: `companies/${provider_id}`,
            method: "GET",
          }
        }

      },
      providesTags: ["Companies"],
    }),

    getFields: builder.query({
      query: (provider_id) => {
        if (provider_id) {
          return {
            url: `fields/${provider_id}`,
            method: "GET",
          }
        }

      },
      providesTags: ["Fields"],

    }),

    getPropertyProviders: builder.query({
      query: (data) => {
        if (data.propertyId && data.providerTypesId && data.skip)
          return {
            url: `property/${data.propertyId}/provider_types/${data.providerTypesId}`,
            method: "GET",
          }

      },
      providesTags: ["Propertyproviders"],

    }),
    getPropertyProvidersByStreamId: builder.query({
      query: (id) => {
        if (id)
          return {
            url: `property_provider/${id}`,
            method: "GET",
          }

      },
      providesTags: ["PropertyProviderStreamId"],

    }),
    propetyType: builder.query({
      query: (token) => ({
        url: "property/type",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      }),
      providesTags: ["Property"],
    }),
    // getPropertyProviders: builder.query({
    //   query: (data) => {
    //     if (data.providerTypesId && data.skip) {
    //       return {
    //         url: `property/${data.propertyId}/provider_types/${data.providerTypesId}`,
    //         method: "GET",
    //       };
    //     }
    //   },
    //   providesTags: ["Propertyproviders"],
    //   skip: (args) => args.property_id === undefined,
    // }),
    getProviderTypeFromPropertyProvider: builder.query({
      query: (property_id) => {
        if (property_id) {
          return {
            url: `provider_types/${property_id}`,
            method: "GET",
          };
        }
      },
      providesTags: ["Providertype"],
      skip: (args) => args.property_id === undefined,
    }),
    getCategory: builder.query({
      query: (type) => {
        if (type) {
          return {
            url: `categories/${type}`,
            method: "GET",
          }
        }

      },
      providesTags: ["Category"],
    }),
    getIncome: builder.query({
      query: () => ({
        url: `incomes`,
        method: "GET",
      }),
      providesTags: ["Income"],
    }),
    getTotalIncome: builder.query({
      query: () => ({
        url: `total_income`,
        method: "GET",
      }),
      providesTags: ["TotalIncome"],
    }),
    getTotalExpense: builder.query({
      query: () => ({
        url: `total_expense`,
        method: "GET",
      }),
      providesTags: ["TotalExpense"],
    }),
    getProfitLoss: builder.query({
      query: () => ({
        url: `profit_loss`,
        method: "GET",
      }),
      providesTags: ["ProfitLoss"],
    }),
    getCurrency: builder.query({
      query: () => ({
        url: `currencies`,
        method: "GET",
      }),
      providesTags: ["Currency"],
    }),
    getUserDetail: builder.query({
      query: () => ({
        url: `user/detail`,
        method: "GET",
        // headers: {
        //   'Authorization': `Bearer ${token}`,
        //   'Content-type': 'application/json; charset=UTF-8',
        //   'Accept': 'application/json'
        // },
      }),
      providesTags: ["Detail"],
    }),
    viewProperty: builder.query({
      query: (id) => {
        if (id) {
          return {
            url: `properties/${id}`,
            method: "GET",
          };
        }
      },
      providesTags: ["ViewProperty"],
    }),



    getSubAccount: builder.query({
      query: () => ({
        url: "sub-account",
        method: "GET",
      }),
      providesTags: ["SubAccount"],
    }),
    getCountries: builder.query({
      query: () => ({
        url: "countries",
        method: "GET",
      }),
      providesTags: ["Country"],
    }),
    getState: builder.query({
      query: (id) => {
        if (id) {
          return {
            url: `states/${id}`,
            method: "GET",
          };
        }
      },
      providesTags: ["State"],
    }),
    getCity: builder.query({
      query: (id) => {
        if (id) {
          return {
            url: `cities/${id}`,
            method: "GET",
          };
        }
      },
      providesTags: ["City"],
    }),
    getAssignProperty: builder.query({
      query: (id) => {
        if (id) {
          return {
            url: `user/${id}/properties`,
            method: "GET",
          };
        }
      },
      providesTags: ["AssignProperty"],
    }),
    categorizePlaidData: builder.query({
      query: (is_recurring) => {
        let url = 'transactions';
   
        if (typeof is_recurring !== 'undefined') {
          url += `?is_recurring=${is_recurring}`;
        }
   
        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["CategorizePlaid"],
    }),
    getLabels: builder.query({
      query: (label) => ({
        url: `form-label/${label}`,
        method: "GET",
      }),
      providesTags: ["labelPlaid"],
    }),
    monthlyExpense: builder.query({
      query: (data) => {
          return{
            url: `monthly-expense?type=${data}`,
            method: "GET",
          }
      },
      providesTags: ["MonthlyExpense"],
    }),
    getAllExpense: builder.query({
      query: ({ provider_type_id, property_id } = {}) => {
        let url = 'get-expense';
    
        // Initialize query parameters array
        const queryParams = [];
    
        // Add provider_type_id to queryParams if it's defined
        if (typeof provider_type_id !== 'undefined') {
          queryParams.push(`provider_type_id=${provider_type_id}`);
        }
    
        // Add property_id to queryParams if it's defined
        if (typeof property_id !== 'undefined') {
          queryParams.push(`property_id=${property_id}`);
        }
    
        // If there are any query parameters, append them to the URL
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
    
        return {
          url,
          method: "GET",
        };
      },
    
      providesTags: ["AllExpense"],
    }),
    
    genrateLinkToken: builder.query({
      query: () => {
          return{
            url: `generate_plaid_token`,
            method: "GET",
          }
      },
      providesTags: ["GenrateLinkToken"],
    }),
    getStatistics: builder.query({
      query: ({ filter_type, filter_value, property_ids } = {}) => {
          let url = `get_statistics`;
          const queryParams = [];
  
          if (filter_type) queryParams.push(`filter_type=${filter_type}`);
          if (filter_value) queryParams.push(`filter_value=${filter_value}`);
          if (property_ids) queryParams.push(`property_ids=${property_ids}`);
  
          if (queryParams.length) {
              url += `?${queryParams.join('&')}`;
          }
  
          return {
              url,
              method: "GET",
          };
      },
      providesTags: ["Statistics"],
  }),
  getTopRevenueProperties: builder.query({
      query: ({ filter_type, filter_value, property_ids } = {}) => {
          let url = `top-revenue-properties`;
          const queryParams = [];
  
          if (filter_type) queryParams.push(`filter_type=${filter_type}`);
          if (filter_value) queryParams.push(`filter_value=${filter_value}`);
          if (property_ids) queryParams.push(`property_ids=${property_ids}`);
  
          if (queryParams.length) {
              url += `?${queryParams.join('&')}`;
          }
  
          return {
              url,
              method: "GET",
          };
      },
      providesTags: ["Revenue"],
  }),
  getTopProfitableProperties: builder.query({
      query: ({ filter_type, filter_value, property_ids } = {}) => {
          let url = `top-profitable-properties`;
          const queryParams = [];
  
          if (filter_type) queryParams.push(`filter_type=${filter_type}`);
          if (filter_value) queryParams.push(`filter_value=${filter_value}`);
          if (property_ids) queryParams.push(`property_ids=${property_ids}`);
  
          if (queryParams.length) {
              url += `?${queryParams.join('&')}`;
          }
          console.log(url,'url')
          return {
              url,
              method: "GET",
          };
      },
      providesTags: ["Profitable"],
  }),
  
getRevenueGrowthComparison: builder.query({
  query: ({ filter_type, filter_value, property_ids } = {}) => {
    let url = `revenue-growth-comparison`;
    const queryParams = [];

    if (filter_type) queryParams.push(`filter_type=${filter_type}`);
    if (filter_value) queryParams.push(`filter_value=${filter_value}`);
    if (property_ids) queryParams.push(`property_ids=${property_ids}`);

    if (queryParams.length) {
        url += `?${queryParams.join('&')}`;
    }

    return {
        url,
        method: "GET",
    };
},
  providesTags: ["Comparison"],
}),
  }),

  tagTypes: [
    "Owner",
    "Property",
    "Group",
    "Providertype",
    "Fields",
    "Provider",
    "ViewProperty",
    "Companies",
    "Propertyproviders",
    "Providertype",
    "Category",
    "Income",
    "TotalIncome",
    "TotalExpense",
    "ProfitLoss",
    "Detail",
    "Statistics",
    "Revenue",
    "SubAccount",
    "Country",
    "State",
    "City",
    "AssignProperty",
    "CategorizePlaid",
    "labelPlaid",
    "PropertyProviderStreamId",
    "MonthlyExpense",
    "AllExpense",
    "GenrateLinkToken",
    "Comparison",
    "Profitable",
    "Statistics"
  ],
});
export const {
  useOwnerTypeQuery,
  useGetPropertiesQuery,
  useGetGroupQuery,
  usePropetyTypeQuery,
  useGetUtilityCategoryQuery,
  useGetUtilityQuery,
  useGetCompanyQuery,
  useGetFieldsQuery,
  useGetPropertyProvidersQuery,
  useGetPropertyProvidersByStreamIdQuery,
  useGetProviderTypeFromPropertyProviderQuery,
  useGetCategoryQuery,
  useGetIncomeQuery,
  useGetTotalIncomeQuery,
  useGetTotalExpenseQuery,
  useGetProfitLossQuery,
  useGetCurrencyQuery,
  useGetUserDetailQuery,
  useViewPropertyQuery,
  useLazyGetTopRevenuePropertiesQuery,
  useGetSubAccountQuery,
  useGetCountriesQuery,
  useGetStateQuery,
  useGetCityQuery,
  useGetAssignPropertyQuery,
  useCategorizePlaidDataQuery,
  useGetLabelsQuery,
  useMonthlyExpenseQuery,
  useGetAllExpenseQuery,
  useGenrateLinkTokenQuery,
  useLazyGetRevenueGrowthComparisonQuery,
  useLazyGetStatisticsQuery,
  useLazyGetTopProfitablePropertiesQuery,

  
} = getApi;
