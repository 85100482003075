import React, { useEffect } from "react";
import { Button,Pagination,Spin,Table,} from "antd";
import Title from "../../component/PageTitle";
import { useLocation, useOutletContext } from "react-router-dom";
import { useState } from "react";
import { useGetAssignPropertyQuery } from "../../slices/getSlice";
import { useUnassignPropertyMutation } from "../../slices/apiSlice";
import { toast } from "react-toastify";


const AssignProperty = () => {
 const user = useLocation()
 const { loading, setLoading } = useOutletContext();
 const { data:assignProperty,refetch,isLoading} = useGetAssignPropertyQuery(user.state.id);
 const [unassignProperty, { }] = useUnassignPropertyMutation()
 const pageSize = 5;
 const [currentPage, setCurrentPage] = useState(1); 
 const dataSource = assignProperty? assignProperty.map((item) => {
    return{
        id:item.id,
        property:item.name_of_property,   
        user_id:item.user_id,
        property_type_id:item.property_type_id
    }      
      })
    : [];
 const totalItems = dataSource ? dataSource.length : 0;
 const startIndex = (currentPage - 1) * pageSize;
 const endIndex = Math.min(startIndex + pageSize, totalItems);
 const displayedItems = dataSource.slice(startIndex, endIndex);

 const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  useEffect(()=>{
if(assignProperty)
{
  setLoading(false)
}
  },[])
  const removeProperty = async (record) => {
  
    const res = await unassignProperty({ userId: user.state.id, recordId: record.id });
    if (res?.data?.message) {
      refetch()
        toast.success(res?.data?.message)
    }
    else{
        toast.error(`${res?.error?.data?.message}`)
    }
};
    const Columns = [
        {
          title: "S.No.",
          dataIndex: "serial",
         render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
          title: "Property Name",
          dataIndex: "property",
        },
      
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
              return (
                <div  className="action-container">
                   
                   <Button type="primary" onClick={()=>removeProperty(record)}>
         Unassign
          </Button>
                      
    
                </div>
              
              );
            },
          },
      ];
  return (
    <>
    {loading?
      <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large" />
    </div>:
    <div className="mainWrapper">
    {/* Button and Title Section */}
    <div className="card titleCard mb-4">
      <div className="card-body">
        <Title title="All Assign Property" />
      </div>
    </div>

    <div className="card tableCard">
      <div className="card-body">
       
              
              <div className="table-responsives">
              
                  
              {
  isLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                         <Spin />
                     </div>
  ) : (
    <>
      <Table
        rowKey={(record) => record.id}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={displayedItems}
        columns={Columns}
        pagination={false}
      />
      {totalItems > 0 && (
        <Pagination
          className="mt-4"
          current={currentPage}
          pageSize={pageSize}
          total={totalItems}
          onChange={handleChangePage}
        />
      )}
    </>
  )
}
               
          </div>
       
      </div>
    </div>
   
  </div>
}</>
  );
};

export default AssignProperty;
